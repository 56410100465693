export const frameData = [
	{
		approved: true,
		backgroundColor: {
			color1: "#FCC989",
			color2: "#98F2E2",
			type: "linear",
		},
		dimensions: {
			bottom: 245,
			height: 2250,
			left: 245,
			right: 245,
			top: 245,
			width: 2250,
		},
		frame: "https://res.cloudinary.com/pritish007/image/upload/v1625150153/Frames/MLH_INIT_GREEN_u5gwqg.png",
		name: "MLH INIT 1",
		renderDimensions: {
			bottom: 71.27272727272727,
			height: 654.5454545454545,
			left: 71.27272727272727,
			right: 71.27272727272727,
			top: 71.27272727272727,
			width: 654.5454545454545,
		},
		shape: "square",
		showTextBox: true,
	},
	{
		approved: true,
		backgroundColor: {
			color1: "#FCC989",
			color2: "#98F2E2",
			type: "linear",
		},
		dimensions: {
			bottom: 245,
			height: 2250,
			left: 245,
			right: 245,
			top: 245,
			width: 2250,
		},
		frame: "https://res.cloudinary.com/pritish007/image/upload/v1625150153/Frames/MLH_INIT_PINK_rlxp5p.png",
		name: "MLH INIT 2",
		renderDimensions: {
			bottom: 71.27272727272727,
			height: 654.5454545454545,
			left: 71.27272727272727,
			right: 71.27272727272727,
			top: 71.27272727272727,
			width: 654.5454545454545,
		},
		shape: "square",
		showTextBox: true,
	},
	{
		approved: true,
		backgroundColor: {
			color1: "#025955",
			color2: "#025955",
			type: "linear",
		},
		dimensions: {
			bottom: 470,
			height: 4501,
			left: 490,
			right: 490,
			top: 470,
			width: 4501,
		},
		frame: "https://res.cloudinary.com/dscnitrourkela/image/upload/v1610220397/icecream_frames/hd9zlgcsw9zjppn0lw9x.png",
		name: "DSC NIT Rourkela",
		renderDimensions: {
			bottom: 71.27272727272727,
			height: 654.5454545454545,
			left: 71.27272727272727,
			right: 71.27272727272727,
			top: 69.29105322763307,
			width: 654.5454545454545,
		},
		shape: "square",
		showTextBox: true,
	},
	{
		approved: true,
		backgroundColor: {
			color1: "#ff396d",
			color2: "#ff396d",
			type: "linear",
		},
		dimensions: {
			bottom: 245,
			height: 2250,
			left: 245,
			right: 245,
			top: 245,
			width: 2250,
		},
		frame: "https://res.cloudinary.com/dscnitrourkela/image/upload/v1610215173/icecream_frames/fvbpjkljamqjaq7kzy7q.png",
		name: "MLH Locak Hack Day 2",
		renderDimensions: {
			bottom: 71.27272727272727,
			height: 654.5454545454545,
			left: 71.27272727272727,
			right: 71.27272727272727,
			top: 71.27272727272727,
			width: 654.5454545454545,
		},
		shape: "square",
		showTextBox: true,
	},
	{
		approved: true,
		backgroundColor: {
			color1: "#f60534",
			color2: "#fed900",
			type: "linear",
		},
		dimensions: {
			bottom: 245,
			height: 2250,
			left: 245,
			right: 245,
			top: 245,
			width: 2250,
		},
		frame: "https://res.cloudinary.com/dscnitrourkela/image/upload/v1610126660/icecream_frames/gkkrhtvcvrv0jrdaaiyx.png",
		name: "MLH Local Hack Day",
		renderDimensions: {
			bottom: 71.27272727272727,
			height: 654.5454545454545,
			left: 71.27272727272727,
			right: 71.27272727272727,
			top: 71.27272727272727,
			width: 654.5454545454545,
		},
		shape: "square",
		showTextBox: true,
	},
	{
		approved: true,
		backgroundColor: {
			color1: "#f12711",
			color2: "#f5af19",
			type: "linear",
		},
		dimensions: {
			bottom: 490,
			height: 4500,
			left: 490,
			right: 490,
			top: 470,
			width: 4500,
		},
		frame: "https://res.cloudinary.com/pritish007/image/upload/v1616326076/Almost_final_frame_p45ci7.png",
		name: "HackNITR 2.0",
		renderDimensions: {
			bottom: 71.03057757644395,
			height: 652.321630804077,
			left: 71.27272727272727,
			right: 71.27272727272727,
			top: 69.29105322763307,
			width: 654.5454545454545,
		},
		shape: "square",
		showTextBox: true,
	},
]
